import { default as _91marketHashName_93_45oldimmZJTXUWBMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName]-old.vue?macro=true";
import { default as _91marketHashName_93rdRRn7j9nXMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName].vue?macro=true";
import { default as chartskq7fZyTGfsMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/charts.vue?macro=true";
import { default as detail_45headerJxqzE0hBy1Meta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/detail-header.vue?macro=true";
import { default as onsaleListxcVpzhD4YQMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/onsaleList.vue?macro=true";
import { default as price_45contentQQCuPRWXM7Meta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/price-content.vue?macro=true";
import { default as saleListv55S2ttHHeMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/saleList.vue?macro=true";
import { default as transactionDetailRxTWHrFRA1Meta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transactionDetail.vue?macro=true";
import { default as transicationtRjAVNRBEvMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transication.vue?macro=true";
import { default as wearRankp7CAhBD7lVMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/wearRank.vue?macro=true";
import { default as _91itemprint_93zNBdkwQVTRMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/tracker/[itemprint].vue?macro=true";
import { default as trackerItemk7Th6ghPXTMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/tracker/components/trackerItem.vue?macro=true";
import { default as indexEup6Lnfqb9Meta } from "/root/workspace/steamdt.com_3as3/pages/[game]/tracker/index.vue?macro=true";
import { default as indexFQDHY8kMTyMeta } from "/root/workspace/steamdt.com_3as3/pages/case/index.vue?macro=true";
import { default as changes8dujYWcqHYMeta } from "/root/workspace/steamdt.com_3as3/pages/changes.vue?macro=true";
import { default as classic7EdM6jzo7IMeta } from "/root/workspace/steamdt.com_3as3/pages/classic.vue?macro=true";
import { default as _404TWkCmWoAc8Meta } from "/root/workspace/steamdt.com_3as3/pages/error/404.vue?macro=true";
import { default as _500YYJKfS2szSMeta } from "/root/workspace/steamdt.com_3as3/pages/error/500.vue?macro=true";
import { default as index2FuuYcpRIYMeta } from "/root/workspace/steamdt.com_3as3/pages/faq/index.vue?macro=true";
import { default as indexFvsfSt3NZMMeta } from "/root/workspace/steamdt.com_3as3/pages/hanging/index.vue?macro=true";
import { default as indexrfJzyOdIBPMeta } from "/root/workspace/steamdt.com_3as3/pages/index.vue?macro=true";
import { default as counterHoXkMYzb0aMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/counter.vue?macro=true";
import { default as indexChartInN09zylwwMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexChart.vue?macro=true";
import { default as indexDrawerT5yeT2AXZzMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexDrawer.vue?macro=true";
import { default as item_45change_45list90dpjTQfTHMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/item-change-list.vue?macro=true";
import { default as itemcompare5X46EmI0EPMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemcompare.vue?macro=true";
import { default as itemListf0ggPZVUDDMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemList.vue?macro=true";
import { default as klinechartsEhhdg416GrMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/klinecharts.vue?macro=true";
import { default as new_45index_45chart3iHVMzc362Meta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/new-index-chart.vue?macro=true";
import { default as online3wCcs0ogFgMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/online.vue?macro=true";
import { default as platformLowestPriceHw3fHRaNJuMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/platformLowestPrice.vue?macro=true";
import { default as scaleNumASTcg0OJeGMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/scaleNum.vue?macro=true";
import { default as transicationUfX7FWUzXdMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/transication.vue?macro=true";
import { default as update_45historyApuSb19KlCMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/update-history.vue?macro=true";
import { default as _91steamId_93ejBKlYzldKMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/[steamId].vue?macro=true";
import { default as InventoryInfoO7TVLrnUSNMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryInfo.vue?macro=true";
import { default as inventoryitemcydpf5jVZhMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/inventoryitem.vue?macro=true";
import { default as InventorySearchAcAJ6bsFFHMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/InventorySearch.vue?macro=true";
import { default as InventoryToplVSRXXtSxKMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryTop.vue?macro=true";
import { default as SevenDaysInventoryqFExv7VYFqMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventory.vue?macro=true";
import { default as SevenDaysInventoryDetailnSgiBE6rgBMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventoryDetail.vue?macro=true";
import { default as indexKDk185p2imMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/index.vue?macro=true";
import { default as myGRehYgZpzFMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/my.vue?macro=true";
import { default as filter_45drawerPjQAyhIu0hMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/filter-drawer.vue?macro=true";
import { default as ladder_45center_45content2a6Rw8xgVIMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/ladder-center-content.vue?macro=true";
import { default as ladders_45lowestPricexVLmwCS7iAMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/ladders-lowestPrice.vue?macro=true";
import { default as price_45dropdownfwyA023jw3Meta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/price-dropdown.vue?macro=true";
import { default as sellNums_45dropdownPy8GwDJFDaMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/sellNums-dropdown.vue?macro=true";
import { default as swap_45pageSHorOX9WYzMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-page.vue?macro=true";
import { default as swap_45platform_45comparesoYNfJVyL6Meta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-platform-compare.vue?macro=true";
import { default as transication_45dropdownx8pT38LJ6MMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/transication-dropdown.vue?macro=true";
import { default as indexwfLcFeARXUMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/index.vue?macro=true";
import { default as steamDKjznFPEztMeta } from "/root/workspace/steamdt.com_3as3/pages/login/steam.vue?macro=true";
import { default as favoriteBLqk7c41HxMeta } from "/root/workspace/steamdt.com_3as3/pages/mkt/favorite.vue?macro=true";
import { default as indexHgbfAsRuJKMeta } from "/root/workspace/steamdt.com_3as3/pages/mkt/index.vue?macro=true";
import { default as addFilterConditiondihcyLKygVMeta } from "/root/workspace/steamdt.com_3as3/pages/selection/components/addFilterCondition.vue?macro=true";
import { default as mkt_45valueViewerevFH4YaIkaMeta } from "/root/workspace/steamdt.com_3as3/pages/selection/components/mkt-valueViewer.vue?macro=true";
import { default as favoriterghmJJqlfLMeta } from "/root/workspace/steamdt.com_3as3/pages/selection/favorite.vue?macro=true";
import { default as indexvUvQkGCFn0Meta } from "/root/workspace/steamdt.com_3as3/pages/selection/index.vue?macro=true";
import { default as chooseSkinDialogmk5wPewZdjMeta } from "/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseSkinDialog.vue?macro=true";
import { default as chooseStickerDialog6R1roFN4afMeta } from "/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseStickerDialog.vue?macro=true";
import { default as itemzqI1eVG2KsMeta } from "/root/workspace/steamdt.com_3as3/pages/skindiy/components/item.vue?macro=true";
import { default as indexYie7rmbEG7Meta } from "/root/workspace/steamdt.com_3as3/pages/skindiy/index.vue?macro=true";
import { default as statusKfT2bRsdkUMeta } from "/root/workspace/steamdt.com_3as3/pages/status.vue?macro=true";
import { default as chartsRMb8V0pZ0iMeta } from "/root/workspace/steamdt.com_3as3/pages/statuscomponents/charts.vue?macro=true";
import { default as _91type_93b2rREbSfGXMeta } from "/root/workspace/steamdt.com_3as3/pages/terms/[type].vue?macro=true";
import { default as indexbcN0HR41p8Meta } from "/root/workspace/steamdt.com_3as3/pages/trend/index.vue?macro=true";
import { default as dataItema1d2sUeq4ZMeta } from "/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/dataItem.vue?macro=true";
import { default as trendTbEIJ82LnXMeta } from "/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/trend.vue?macro=true";
import { default as updatedqV7MR5cdcMeta } from "/root/workspace/steamdt.com_3as3/pages/update.vue?macro=true";
export default [
  {
    name: _91marketHashName_93_45oldimmZJTXUWBMeta?.name ?? "game-marketHashName-old___en",
    path: _91marketHashName_93_45oldimmZJTXUWBMeta?.path ?? "/en/:game()/:marketHashName()-old",
    meta: _91marketHashName_93_45oldimmZJTXUWBMeta || {},
    alias: _91marketHashName_93_45oldimmZJTXUWBMeta?.alias || [],
    redirect: _91marketHashName_93_45oldimmZJTXUWBMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName]-old.vue").then(m => m.default || m)
  },
  {
    name: _91marketHashName_93_45oldimmZJTXUWBMeta?.name ?? "game-marketHashName-old___zh___default",
    path: _91marketHashName_93_45oldimmZJTXUWBMeta?.path ?? "/:game()/:marketHashName()-old",
    meta: _91marketHashName_93_45oldimmZJTXUWBMeta || {},
    alias: _91marketHashName_93_45oldimmZJTXUWBMeta?.alias || [],
    redirect: _91marketHashName_93_45oldimmZJTXUWBMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName]-old.vue").then(m => m.default || m)
  },
  {
    name: _91marketHashName_93_45oldimmZJTXUWBMeta?.name ?? "game-marketHashName-old___zh",
    path: _91marketHashName_93_45oldimmZJTXUWBMeta?.path ?? "/zh/:game()/:marketHashName()-old",
    meta: _91marketHashName_93_45oldimmZJTXUWBMeta || {},
    alias: _91marketHashName_93_45oldimmZJTXUWBMeta?.alias || [],
    redirect: _91marketHashName_93_45oldimmZJTXUWBMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName]-old.vue").then(m => m.default || m)
  },
  {
    name: _91marketHashName_93rdRRn7j9nXMeta?.name ?? "game-marketHashName___en",
    path: _91marketHashName_93rdRRn7j9nXMeta?.path ?? "/en/:game()/:marketHashName()",
    meta: _91marketHashName_93rdRRn7j9nXMeta || {},
    alias: _91marketHashName_93rdRRn7j9nXMeta?.alias || [],
    redirect: _91marketHashName_93rdRRn7j9nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName].vue").then(m => m.default || m)
  },
  {
    name: _91marketHashName_93rdRRn7j9nXMeta?.name ?? "game-marketHashName___zh___default",
    path: _91marketHashName_93rdRRn7j9nXMeta?.path ?? "/:game()/:marketHashName()",
    meta: _91marketHashName_93rdRRn7j9nXMeta || {},
    alias: _91marketHashName_93rdRRn7j9nXMeta?.alias || [],
    redirect: _91marketHashName_93rdRRn7j9nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName].vue").then(m => m.default || m)
  },
  {
    name: _91marketHashName_93rdRRn7j9nXMeta?.name ?? "game-marketHashName___zh",
    path: _91marketHashName_93rdRRn7j9nXMeta?.path ?? "/zh/:game()/:marketHashName()",
    meta: _91marketHashName_93rdRRn7j9nXMeta || {},
    alias: _91marketHashName_93rdRRn7j9nXMeta?.alias || [],
    redirect: _91marketHashName_93rdRRn7j9nXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName].vue").then(m => m.default || m)
  },
  {
    name: chartskq7fZyTGfsMeta?.name ?? "game-itemDetailComponents-charts___en",
    path: chartskq7fZyTGfsMeta?.path ?? "/en/:game()/itemDetailComponents/charts",
    meta: chartskq7fZyTGfsMeta || {},
    alias: chartskq7fZyTGfsMeta?.alias || [],
    redirect: chartskq7fZyTGfsMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/charts.vue").then(m => m.default || m)
  },
  {
    name: chartskq7fZyTGfsMeta?.name ?? "game-itemDetailComponents-charts___zh___default",
    path: chartskq7fZyTGfsMeta?.path ?? "/:game()/itemDetailComponents/charts",
    meta: chartskq7fZyTGfsMeta || {},
    alias: chartskq7fZyTGfsMeta?.alias || [],
    redirect: chartskq7fZyTGfsMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/charts.vue").then(m => m.default || m)
  },
  {
    name: chartskq7fZyTGfsMeta?.name ?? "game-itemDetailComponents-charts___zh",
    path: chartskq7fZyTGfsMeta?.path ?? "/zh/:game()/itemDetailComponents/charts",
    meta: chartskq7fZyTGfsMeta || {},
    alias: chartskq7fZyTGfsMeta?.alias || [],
    redirect: chartskq7fZyTGfsMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/charts.vue").then(m => m.default || m)
  },
  {
    name: detail_45headerJxqzE0hBy1Meta?.name ?? "game-itemDetailComponents-detail-header___en",
    path: detail_45headerJxqzE0hBy1Meta?.path ?? "/en/:game()/itemDetailComponents/detail-header",
    meta: detail_45headerJxqzE0hBy1Meta || {},
    alias: detail_45headerJxqzE0hBy1Meta?.alias || [],
    redirect: detail_45headerJxqzE0hBy1Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/detail-header.vue").then(m => m.default || m)
  },
  {
    name: detail_45headerJxqzE0hBy1Meta?.name ?? "game-itemDetailComponents-detail-header___zh___default",
    path: detail_45headerJxqzE0hBy1Meta?.path ?? "/:game()/itemDetailComponents/detail-header",
    meta: detail_45headerJxqzE0hBy1Meta || {},
    alias: detail_45headerJxqzE0hBy1Meta?.alias || [],
    redirect: detail_45headerJxqzE0hBy1Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/detail-header.vue").then(m => m.default || m)
  },
  {
    name: detail_45headerJxqzE0hBy1Meta?.name ?? "game-itemDetailComponents-detail-header___zh",
    path: detail_45headerJxqzE0hBy1Meta?.path ?? "/zh/:game()/itemDetailComponents/detail-header",
    meta: detail_45headerJxqzE0hBy1Meta || {},
    alias: detail_45headerJxqzE0hBy1Meta?.alias || [],
    redirect: detail_45headerJxqzE0hBy1Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/detail-header.vue").then(m => m.default || m)
  },
  {
    name: onsaleListxcVpzhD4YQMeta?.name ?? "game-itemDetailComponents-onsaleList___en",
    path: onsaleListxcVpzhD4YQMeta?.path ?? "/en/:game()/itemDetailComponents/onsaleList",
    meta: onsaleListxcVpzhD4YQMeta || {},
    alias: onsaleListxcVpzhD4YQMeta?.alias || [],
    redirect: onsaleListxcVpzhD4YQMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/onsaleList.vue").then(m => m.default || m)
  },
  {
    name: onsaleListxcVpzhD4YQMeta?.name ?? "game-itemDetailComponents-onsaleList___zh___default",
    path: onsaleListxcVpzhD4YQMeta?.path ?? "/:game()/itemDetailComponents/onsaleList",
    meta: onsaleListxcVpzhD4YQMeta || {},
    alias: onsaleListxcVpzhD4YQMeta?.alias || [],
    redirect: onsaleListxcVpzhD4YQMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/onsaleList.vue").then(m => m.default || m)
  },
  {
    name: onsaleListxcVpzhD4YQMeta?.name ?? "game-itemDetailComponents-onsaleList___zh",
    path: onsaleListxcVpzhD4YQMeta?.path ?? "/zh/:game()/itemDetailComponents/onsaleList",
    meta: onsaleListxcVpzhD4YQMeta || {},
    alias: onsaleListxcVpzhD4YQMeta?.alias || [],
    redirect: onsaleListxcVpzhD4YQMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/onsaleList.vue").then(m => m.default || m)
  },
  {
    name: price_45contentQQCuPRWXM7Meta?.name ?? "game-itemDetailComponents-price-content___en",
    path: price_45contentQQCuPRWXM7Meta?.path ?? "/en/:game()/itemDetailComponents/price-content",
    meta: price_45contentQQCuPRWXM7Meta || {},
    alias: price_45contentQQCuPRWXM7Meta?.alias || [],
    redirect: price_45contentQQCuPRWXM7Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/price-content.vue").then(m => m.default || m)
  },
  {
    name: price_45contentQQCuPRWXM7Meta?.name ?? "game-itemDetailComponents-price-content___zh___default",
    path: price_45contentQQCuPRWXM7Meta?.path ?? "/:game()/itemDetailComponents/price-content",
    meta: price_45contentQQCuPRWXM7Meta || {},
    alias: price_45contentQQCuPRWXM7Meta?.alias || [],
    redirect: price_45contentQQCuPRWXM7Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/price-content.vue").then(m => m.default || m)
  },
  {
    name: price_45contentQQCuPRWXM7Meta?.name ?? "game-itemDetailComponents-price-content___zh",
    path: price_45contentQQCuPRWXM7Meta?.path ?? "/zh/:game()/itemDetailComponents/price-content",
    meta: price_45contentQQCuPRWXM7Meta || {},
    alias: price_45contentQQCuPRWXM7Meta?.alias || [],
    redirect: price_45contentQQCuPRWXM7Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/price-content.vue").then(m => m.default || m)
  },
  {
    name: saleListv55S2ttHHeMeta?.name ?? "game-itemDetailComponents-saleList___en",
    path: saleListv55S2ttHHeMeta?.path ?? "/en/:game()/itemDetailComponents/saleList",
    meta: saleListv55S2ttHHeMeta || {},
    alias: saleListv55S2ttHHeMeta?.alias || [],
    redirect: saleListv55S2ttHHeMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/saleList.vue").then(m => m.default || m)
  },
  {
    name: saleListv55S2ttHHeMeta?.name ?? "game-itemDetailComponents-saleList___zh___default",
    path: saleListv55S2ttHHeMeta?.path ?? "/:game()/itemDetailComponents/saleList",
    meta: saleListv55S2ttHHeMeta || {},
    alias: saleListv55S2ttHHeMeta?.alias || [],
    redirect: saleListv55S2ttHHeMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/saleList.vue").then(m => m.default || m)
  },
  {
    name: saleListv55S2ttHHeMeta?.name ?? "game-itemDetailComponents-saleList___zh",
    path: saleListv55S2ttHHeMeta?.path ?? "/zh/:game()/itemDetailComponents/saleList",
    meta: saleListv55S2ttHHeMeta || {},
    alias: saleListv55S2ttHHeMeta?.alias || [],
    redirect: saleListv55S2ttHHeMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/saleList.vue").then(m => m.default || m)
  },
  {
    name: transactionDetailRxTWHrFRA1Meta?.name ?? "game-itemDetailComponents-transactionDetail___en",
    path: transactionDetailRxTWHrFRA1Meta?.path ?? "/en/:game()/itemDetailComponents/transactionDetail",
    meta: transactionDetailRxTWHrFRA1Meta || {},
    alias: transactionDetailRxTWHrFRA1Meta?.alias || [],
    redirect: transactionDetailRxTWHrFRA1Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transactionDetail.vue").then(m => m.default || m)
  },
  {
    name: transactionDetailRxTWHrFRA1Meta?.name ?? "game-itemDetailComponents-transactionDetail___zh___default",
    path: transactionDetailRxTWHrFRA1Meta?.path ?? "/:game()/itemDetailComponents/transactionDetail",
    meta: transactionDetailRxTWHrFRA1Meta || {},
    alias: transactionDetailRxTWHrFRA1Meta?.alias || [],
    redirect: transactionDetailRxTWHrFRA1Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transactionDetail.vue").then(m => m.default || m)
  },
  {
    name: transactionDetailRxTWHrFRA1Meta?.name ?? "game-itemDetailComponents-transactionDetail___zh",
    path: transactionDetailRxTWHrFRA1Meta?.path ?? "/zh/:game()/itemDetailComponents/transactionDetail",
    meta: transactionDetailRxTWHrFRA1Meta || {},
    alias: transactionDetailRxTWHrFRA1Meta?.alias || [],
    redirect: transactionDetailRxTWHrFRA1Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transactionDetail.vue").then(m => m.default || m)
  },
  {
    name: transicationtRjAVNRBEvMeta?.name ?? "game-itemDetailComponents-transication___en",
    path: transicationtRjAVNRBEvMeta?.path ?? "/en/:game()/itemDetailComponents/transication",
    meta: transicationtRjAVNRBEvMeta || {},
    alias: transicationtRjAVNRBEvMeta?.alias || [],
    redirect: transicationtRjAVNRBEvMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transication.vue").then(m => m.default || m)
  },
  {
    name: transicationtRjAVNRBEvMeta?.name ?? "game-itemDetailComponents-transication___zh___default",
    path: transicationtRjAVNRBEvMeta?.path ?? "/:game()/itemDetailComponents/transication",
    meta: transicationtRjAVNRBEvMeta || {},
    alias: transicationtRjAVNRBEvMeta?.alias || [],
    redirect: transicationtRjAVNRBEvMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transication.vue").then(m => m.default || m)
  },
  {
    name: transicationtRjAVNRBEvMeta?.name ?? "game-itemDetailComponents-transication___zh",
    path: transicationtRjAVNRBEvMeta?.path ?? "/zh/:game()/itemDetailComponents/transication",
    meta: transicationtRjAVNRBEvMeta || {},
    alias: transicationtRjAVNRBEvMeta?.alias || [],
    redirect: transicationtRjAVNRBEvMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transication.vue").then(m => m.default || m)
  },
  {
    name: wearRankp7CAhBD7lVMeta?.name ?? "game-itemDetailComponents-wearRank___en",
    path: wearRankp7CAhBD7lVMeta?.path ?? "/en/:game()/itemDetailComponents/wearRank",
    meta: wearRankp7CAhBD7lVMeta || {},
    alias: wearRankp7CAhBD7lVMeta?.alias || [],
    redirect: wearRankp7CAhBD7lVMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/wearRank.vue").then(m => m.default || m)
  },
  {
    name: wearRankp7CAhBD7lVMeta?.name ?? "game-itemDetailComponents-wearRank___zh___default",
    path: wearRankp7CAhBD7lVMeta?.path ?? "/:game()/itemDetailComponents/wearRank",
    meta: wearRankp7CAhBD7lVMeta || {},
    alias: wearRankp7CAhBD7lVMeta?.alias || [],
    redirect: wearRankp7CAhBD7lVMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/wearRank.vue").then(m => m.default || m)
  },
  {
    name: wearRankp7CAhBD7lVMeta?.name ?? "game-itemDetailComponents-wearRank___zh",
    path: wearRankp7CAhBD7lVMeta?.path ?? "/zh/:game()/itemDetailComponents/wearRank",
    meta: wearRankp7CAhBD7lVMeta || {},
    alias: wearRankp7CAhBD7lVMeta?.alias || [],
    redirect: wearRankp7CAhBD7lVMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/wearRank.vue").then(m => m.default || m)
  },
  {
    name: _91itemprint_93zNBdkwQVTRMeta?.name ?? "game-tracker-itemprint___en",
    path: _91itemprint_93zNBdkwQVTRMeta?.path ?? "/en/:game()/tracker/:itemprint()",
    meta: _91itemprint_93zNBdkwQVTRMeta || {},
    alias: _91itemprint_93zNBdkwQVTRMeta?.alias || [],
    redirect: _91itemprint_93zNBdkwQVTRMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/[itemprint].vue").then(m => m.default || m)
  },
  {
    name: _91itemprint_93zNBdkwQVTRMeta?.name ?? "game-tracker-itemprint___zh___default",
    path: _91itemprint_93zNBdkwQVTRMeta?.path ?? "/:game()/tracker/:itemprint()",
    meta: _91itemprint_93zNBdkwQVTRMeta || {},
    alias: _91itemprint_93zNBdkwQVTRMeta?.alias || [],
    redirect: _91itemprint_93zNBdkwQVTRMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/[itemprint].vue").then(m => m.default || m)
  },
  {
    name: _91itemprint_93zNBdkwQVTRMeta?.name ?? "game-tracker-itemprint___zh",
    path: _91itemprint_93zNBdkwQVTRMeta?.path ?? "/zh/:game()/tracker/:itemprint()",
    meta: _91itemprint_93zNBdkwQVTRMeta || {},
    alias: _91itemprint_93zNBdkwQVTRMeta?.alias || [],
    redirect: _91itemprint_93zNBdkwQVTRMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/[itemprint].vue").then(m => m.default || m)
  },
  {
    name: trackerItemk7Th6ghPXTMeta?.name ?? "game-tracker-components-trackerItem___en",
    path: trackerItemk7Th6ghPXTMeta?.path ?? "/en/:game()/tracker/components/trackerItem",
    meta: trackerItemk7Th6ghPXTMeta || {},
    alias: trackerItemk7Th6ghPXTMeta?.alias || [],
    redirect: trackerItemk7Th6ghPXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/components/trackerItem.vue").then(m => m.default || m)
  },
  {
    name: trackerItemk7Th6ghPXTMeta?.name ?? "game-tracker-components-trackerItem___zh___default",
    path: trackerItemk7Th6ghPXTMeta?.path ?? "/:game()/tracker/components/trackerItem",
    meta: trackerItemk7Th6ghPXTMeta || {},
    alias: trackerItemk7Th6ghPXTMeta?.alias || [],
    redirect: trackerItemk7Th6ghPXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/components/trackerItem.vue").then(m => m.default || m)
  },
  {
    name: trackerItemk7Th6ghPXTMeta?.name ?? "game-tracker-components-trackerItem___zh",
    path: trackerItemk7Th6ghPXTMeta?.path ?? "/zh/:game()/tracker/components/trackerItem",
    meta: trackerItemk7Th6ghPXTMeta || {},
    alias: trackerItemk7Th6ghPXTMeta?.alias || [],
    redirect: trackerItemk7Th6ghPXTMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/components/trackerItem.vue").then(m => m.default || m)
  },
  {
    name: indexEup6Lnfqb9Meta?.name ?? "game-tracker___en",
    path: indexEup6Lnfqb9Meta?.path ?? "/en/:game()/tracker",
    meta: indexEup6Lnfqb9Meta || {},
    alias: indexEup6Lnfqb9Meta?.alias || [],
    redirect: indexEup6Lnfqb9Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/index.vue").then(m => m.default || m)
  },
  {
    name: indexEup6Lnfqb9Meta?.name ?? "game-tracker___zh___default",
    path: indexEup6Lnfqb9Meta?.path ?? "/:game()/tracker",
    meta: indexEup6Lnfqb9Meta || {},
    alias: indexEup6Lnfqb9Meta?.alias || [],
    redirect: indexEup6Lnfqb9Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/index.vue").then(m => m.default || m)
  },
  {
    name: indexEup6Lnfqb9Meta?.name ?? "game-tracker___zh",
    path: indexEup6Lnfqb9Meta?.path ?? "/zh/:game()/tracker",
    meta: indexEup6Lnfqb9Meta || {},
    alias: indexEup6Lnfqb9Meta?.alias || [],
    redirect: indexEup6Lnfqb9Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/index.vue").then(m => m.default || m)
  },
  {
    name: indexFQDHY8kMTyMeta?.name ?? "case___en",
    path: indexFQDHY8kMTyMeta?.path ?? "/en/case",
    meta: indexFQDHY8kMTyMeta || {},
    alias: indexFQDHY8kMTyMeta?.alias || [],
    redirect: indexFQDHY8kMTyMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/case/index.vue").then(m => m.default || m)
  },
  {
    name: indexFQDHY8kMTyMeta?.name ?? "case___zh___default",
    path: indexFQDHY8kMTyMeta?.path ?? "/case",
    meta: indexFQDHY8kMTyMeta || {},
    alias: indexFQDHY8kMTyMeta?.alias || [],
    redirect: indexFQDHY8kMTyMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/case/index.vue").then(m => m.default || m)
  },
  {
    name: indexFQDHY8kMTyMeta?.name ?? "case___zh",
    path: indexFQDHY8kMTyMeta?.path ?? "/zh/case",
    meta: indexFQDHY8kMTyMeta || {},
    alias: indexFQDHY8kMTyMeta?.alias || [],
    redirect: indexFQDHY8kMTyMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/case/index.vue").then(m => m.default || m)
  },
  {
    name: changes8dujYWcqHYMeta?.name ?? "changes___en",
    path: changes8dujYWcqHYMeta?.path ?? "/en/changes",
    meta: changes8dujYWcqHYMeta || {},
    alias: changes8dujYWcqHYMeta?.alias || [],
    redirect: changes8dujYWcqHYMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/changes.vue").then(m => m.default || m)
  },
  {
    name: changes8dujYWcqHYMeta?.name ?? "changes___zh___default",
    path: changes8dujYWcqHYMeta?.path ?? "/changes",
    meta: changes8dujYWcqHYMeta || {},
    alias: changes8dujYWcqHYMeta?.alias || [],
    redirect: changes8dujYWcqHYMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/changes.vue").then(m => m.default || m)
  },
  {
    name: changes8dujYWcqHYMeta?.name ?? "changes___zh",
    path: changes8dujYWcqHYMeta?.path ?? "/zh/changes",
    meta: changes8dujYWcqHYMeta || {},
    alias: changes8dujYWcqHYMeta?.alias || [],
    redirect: changes8dujYWcqHYMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/changes.vue").then(m => m.default || m)
  },
  {
    name: classic7EdM6jzo7IMeta?.name ?? "classic___en",
    path: classic7EdM6jzo7IMeta?.path ?? "/en/classic",
    meta: classic7EdM6jzo7IMeta || {},
    alias: classic7EdM6jzo7IMeta?.alias || [],
    redirect: classic7EdM6jzo7IMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/classic.vue").then(m => m.default || m)
  },
  {
    name: classic7EdM6jzo7IMeta?.name ?? "classic___zh___default",
    path: classic7EdM6jzo7IMeta?.path ?? "/classic",
    meta: classic7EdM6jzo7IMeta || {},
    alias: classic7EdM6jzo7IMeta?.alias || [],
    redirect: classic7EdM6jzo7IMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/classic.vue").then(m => m.default || m)
  },
  {
    name: classic7EdM6jzo7IMeta?.name ?? "classic___zh",
    path: classic7EdM6jzo7IMeta?.path ?? "/zh/classic",
    meta: classic7EdM6jzo7IMeta || {},
    alias: classic7EdM6jzo7IMeta?.alias || [],
    redirect: classic7EdM6jzo7IMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/classic.vue").then(m => m.default || m)
  },
  {
    name: _404TWkCmWoAc8Meta?.name ?? "error-404___en",
    path: _404TWkCmWoAc8Meta?.path ?? "/en/error/404",
    meta: _404TWkCmWoAc8Meta || {},
    alias: _404TWkCmWoAc8Meta?.alias || [],
    redirect: _404TWkCmWoAc8Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _404TWkCmWoAc8Meta?.name ?? "error-404___zh___default",
    path: _404TWkCmWoAc8Meta?.path ?? "/error/404",
    meta: _404TWkCmWoAc8Meta || {},
    alias: _404TWkCmWoAc8Meta?.alias || [],
    redirect: _404TWkCmWoAc8Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _404TWkCmWoAc8Meta?.name ?? "error-404___zh",
    path: _404TWkCmWoAc8Meta?.path ?? "/zh/error/404",
    meta: _404TWkCmWoAc8Meta || {},
    alias: _404TWkCmWoAc8Meta?.alias || [],
    redirect: _404TWkCmWoAc8Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _500YYJKfS2szSMeta?.name ?? "error-500___en",
    path: _500YYJKfS2szSMeta?.path ?? "/en/error/500",
    meta: _500YYJKfS2szSMeta || {},
    alias: _500YYJKfS2szSMeta?.alias || [],
    redirect: _500YYJKfS2szSMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500YYJKfS2szSMeta?.name ?? "error-500___zh___default",
    path: _500YYJKfS2szSMeta?.path ?? "/error/500",
    meta: _500YYJKfS2szSMeta || {},
    alias: _500YYJKfS2szSMeta?.alias || [],
    redirect: _500YYJKfS2szSMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500YYJKfS2szSMeta?.name ?? "error-500___zh",
    path: _500YYJKfS2szSMeta?.path ?? "/zh/error/500",
    meta: _500YYJKfS2szSMeta || {},
    alias: _500YYJKfS2szSMeta?.alias || [],
    redirect: _500YYJKfS2szSMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: index2FuuYcpRIYMeta?.name ?? "faq___en",
    path: index2FuuYcpRIYMeta?.path ?? "/en/faq",
    meta: index2FuuYcpRIYMeta || {},
    alias: index2FuuYcpRIYMeta?.alias || [],
    redirect: index2FuuYcpRIYMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index2FuuYcpRIYMeta?.name ?? "faq___zh___default",
    path: index2FuuYcpRIYMeta?.path ?? "/faq",
    meta: index2FuuYcpRIYMeta || {},
    alias: index2FuuYcpRIYMeta?.alias || [],
    redirect: index2FuuYcpRIYMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index2FuuYcpRIYMeta?.name ?? "faq___zh",
    path: index2FuuYcpRIYMeta?.path ?? "/zh/faq",
    meta: index2FuuYcpRIYMeta || {},
    alias: index2FuuYcpRIYMeta?.alias || [],
    redirect: index2FuuYcpRIYMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexFvsfSt3NZMMeta?.name ?? "hanging___en",
    path: indexFvsfSt3NZMMeta?.path ?? "/en/hanging",
    meta: indexFvsfSt3NZMMeta || {},
    alias: indexFvsfSt3NZMMeta?.alias || [],
    redirect: indexFvsfSt3NZMMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/hanging/index.vue").then(m => m.default || m)
  },
  {
    name: indexFvsfSt3NZMMeta?.name ?? "hanging___zh___default",
    path: indexFvsfSt3NZMMeta?.path ?? "/hanging",
    meta: indexFvsfSt3NZMMeta || {},
    alias: indexFvsfSt3NZMMeta?.alias || [],
    redirect: indexFvsfSt3NZMMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/hanging/index.vue").then(m => m.default || m)
  },
  {
    name: indexFvsfSt3NZMMeta?.name ?? "hanging___zh",
    path: indexFvsfSt3NZMMeta?.path ?? "/zh/hanging",
    meta: indexFvsfSt3NZMMeta || {},
    alias: indexFvsfSt3NZMMeta?.alias || [],
    redirect: indexFvsfSt3NZMMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/hanging/index.vue").then(m => m.default || m)
  },
  {
    name: indexrfJzyOdIBPMeta?.name ?? "index___en",
    path: indexrfJzyOdIBPMeta?.path ?? "/en",
    meta: indexrfJzyOdIBPMeta || {},
    alias: indexrfJzyOdIBPMeta?.alias || [],
    redirect: indexrfJzyOdIBPMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexrfJzyOdIBPMeta?.name ?? "index___zh___default",
    path: indexrfJzyOdIBPMeta?.path ?? "/",
    meta: indexrfJzyOdIBPMeta || {},
    alias: indexrfJzyOdIBPMeta?.alias || [],
    redirect: indexrfJzyOdIBPMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexrfJzyOdIBPMeta?.name ?? "index___zh",
    path: indexrfJzyOdIBPMeta?.path ?? "/zh",
    meta: indexrfJzyOdIBPMeta || {},
    alias: indexrfJzyOdIBPMeta?.alias || [],
    redirect: indexrfJzyOdIBPMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: counterHoXkMYzb0aMeta?.name ?? "indexcomponents-counter___en",
    path: counterHoXkMYzb0aMeta?.path ?? "/en/indexcomponents/counter",
    meta: counterHoXkMYzb0aMeta || {},
    alias: counterHoXkMYzb0aMeta?.alias || [],
    redirect: counterHoXkMYzb0aMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/counter.vue").then(m => m.default || m)
  },
  {
    name: counterHoXkMYzb0aMeta?.name ?? "indexcomponents-counter___zh___default",
    path: counterHoXkMYzb0aMeta?.path ?? "/indexcomponents/counter",
    meta: counterHoXkMYzb0aMeta || {},
    alias: counterHoXkMYzb0aMeta?.alias || [],
    redirect: counterHoXkMYzb0aMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/counter.vue").then(m => m.default || m)
  },
  {
    name: counterHoXkMYzb0aMeta?.name ?? "indexcomponents-counter___zh",
    path: counterHoXkMYzb0aMeta?.path ?? "/zh/indexcomponents/counter",
    meta: counterHoXkMYzb0aMeta || {},
    alias: counterHoXkMYzb0aMeta?.alias || [],
    redirect: counterHoXkMYzb0aMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/counter.vue").then(m => m.default || m)
  },
  {
    name: indexChartInN09zylwwMeta?.name ?? "indexcomponents-indexChart___en",
    path: indexChartInN09zylwwMeta?.path ?? "/en/indexcomponents/indexChart",
    meta: indexChartInN09zylwwMeta || {},
    alias: indexChartInN09zylwwMeta?.alias || [],
    redirect: indexChartInN09zylwwMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexChart.vue").then(m => m.default || m)
  },
  {
    name: indexChartInN09zylwwMeta?.name ?? "indexcomponents-indexChart___zh___default",
    path: indexChartInN09zylwwMeta?.path ?? "/indexcomponents/indexChart",
    meta: indexChartInN09zylwwMeta || {},
    alias: indexChartInN09zylwwMeta?.alias || [],
    redirect: indexChartInN09zylwwMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexChart.vue").then(m => m.default || m)
  },
  {
    name: indexChartInN09zylwwMeta?.name ?? "indexcomponents-indexChart___zh",
    path: indexChartInN09zylwwMeta?.path ?? "/zh/indexcomponents/indexChart",
    meta: indexChartInN09zylwwMeta || {},
    alias: indexChartInN09zylwwMeta?.alias || [],
    redirect: indexChartInN09zylwwMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexChart.vue").then(m => m.default || m)
  },
  {
    name: indexDrawerT5yeT2AXZzMeta?.name ?? "indexcomponents-indexDrawer___en",
    path: indexDrawerT5yeT2AXZzMeta?.path ?? "/en/indexcomponents/indexDrawer",
    meta: indexDrawerT5yeT2AXZzMeta || {},
    alias: indexDrawerT5yeT2AXZzMeta?.alias || [],
    redirect: indexDrawerT5yeT2AXZzMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexDrawer.vue").then(m => m.default || m)
  },
  {
    name: indexDrawerT5yeT2AXZzMeta?.name ?? "indexcomponents-indexDrawer___zh___default",
    path: indexDrawerT5yeT2AXZzMeta?.path ?? "/indexcomponents/indexDrawer",
    meta: indexDrawerT5yeT2AXZzMeta || {},
    alias: indexDrawerT5yeT2AXZzMeta?.alias || [],
    redirect: indexDrawerT5yeT2AXZzMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexDrawer.vue").then(m => m.default || m)
  },
  {
    name: indexDrawerT5yeT2AXZzMeta?.name ?? "indexcomponents-indexDrawer___zh",
    path: indexDrawerT5yeT2AXZzMeta?.path ?? "/zh/indexcomponents/indexDrawer",
    meta: indexDrawerT5yeT2AXZzMeta || {},
    alias: indexDrawerT5yeT2AXZzMeta?.alias || [],
    redirect: indexDrawerT5yeT2AXZzMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexDrawer.vue").then(m => m.default || m)
  },
  {
    name: item_45change_45list90dpjTQfTHMeta?.name ?? "indexcomponents-item-change-list___en",
    path: item_45change_45list90dpjTQfTHMeta?.path ?? "/en/indexcomponents/item-change-list",
    meta: item_45change_45list90dpjTQfTHMeta || {},
    alias: item_45change_45list90dpjTQfTHMeta?.alias || [],
    redirect: item_45change_45list90dpjTQfTHMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/item-change-list.vue").then(m => m.default || m)
  },
  {
    name: item_45change_45list90dpjTQfTHMeta?.name ?? "indexcomponents-item-change-list___zh___default",
    path: item_45change_45list90dpjTQfTHMeta?.path ?? "/indexcomponents/item-change-list",
    meta: item_45change_45list90dpjTQfTHMeta || {},
    alias: item_45change_45list90dpjTQfTHMeta?.alias || [],
    redirect: item_45change_45list90dpjTQfTHMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/item-change-list.vue").then(m => m.default || m)
  },
  {
    name: item_45change_45list90dpjTQfTHMeta?.name ?? "indexcomponents-item-change-list___zh",
    path: item_45change_45list90dpjTQfTHMeta?.path ?? "/zh/indexcomponents/item-change-list",
    meta: item_45change_45list90dpjTQfTHMeta || {},
    alias: item_45change_45list90dpjTQfTHMeta?.alias || [],
    redirect: item_45change_45list90dpjTQfTHMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/item-change-list.vue").then(m => m.default || m)
  },
  {
    name: itemcompare5X46EmI0EPMeta?.name ?? "indexcomponents-itemcompare___en",
    path: itemcompare5X46EmI0EPMeta?.path ?? "/en/indexcomponents/itemcompare",
    meta: itemcompare5X46EmI0EPMeta || {},
    alias: itemcompare5X46EmI0EPMeta?.alias || [],
    redirect: itemcompare5X46EmI0EPMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemcompare.vue").then(m => m.default || m)
  },
  {
    name: itemcompare5X46EmI0EPMeta?.name ?? "indexcomponents-itemcompare___zh___default",
    path: itemcompare5X46EmI0EPMeta?.path ?? "/indexcomponents/itemcompare",
    meta: itemcompare5X46EmI0EPMeta || {},
    alias: itemcompare5X46EmI0EPMeta?.alias || [],
    redirect: itemcompare5X46EmI0EPMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemcompare.vue").then(m => m.default || m)
  },
  {
    name: itemcompare5X46EmI0EPMeta?.name ?? "indexcomponents-itemcompare___zh",
    path: itemcompare5X46EmI0EPMeta?.path ?? "/zh/indexcomponents/itemcompare",
    meta: itemcompare5X46EmI0EPMeta || {},
    alias: itemcompare5X46EmI0EPMeta?.alias || [],
    redirect: itemcompare5X46EmI0EPMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemcompare.vue").then(m => m.default || m)
  },
  {
    name: itemListf0ggPZVUDDMeta?.name ?? "indexcomponents-itemList___en",
    path: itemListf0ggPZVUDDMeta?.path ?? "/en/indexcomponents/itemList",
    meta: itemListf0ggPZVUDDMeta || {},
    alias: itemListf0ggPZVUDDMeta?.alias || [],
    redirect: itemListf0ggPZVUDDMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemList.vue").then(m => m.default || m)
  },
  {
    name: itemListf0ggPZVUDDMeta?.name ?? "indexcomponents-itemList___zh___default",
    path: itemListf0ggPZVUDDMeta?.path ?? "/indexcomponents/itemList",
    meta: itemListf0ggPZVUDDMeta || {},
    alias: itemListf0ggPZVUDDMeta?.alias || [],
    redirect: itemListf0ggPZVUDDMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemList.vue").then(m => m.default || m)
  },
  {
    name: itemListf0ggPZVUDDMeta?.name ?? "indexcomponents-itemList___zh",
    path: itemListf0ggPZVUDDMeta?.path ?? "/zh/indexcomponents/itemList",
    meta: itemListf0ggPZVUDDMeta || {},
    alias: itemListf0ggPZVUDDMeta?.alias || [],
    redirect: itemListf0ggPZVUDDMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemList.vue").then(m => m.default || m)
  },
  {
    name: klinechartsEhhdg416GrMeta?.name ?? "indexcomponents-klinecharts___en",
    path: klinechartsEhhdg416GrMeta?.path ?? "/en/indexcomponents/klinecharts",
    meta: klinechartsEhhdg416GrMeta || {},
    alias: klinechartsEhhdg416GrMeta?.alias || [],
    redirect: klinechartsEhhdg416GrMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/klinecharts.vue").then(m => m.default || m)
  },
  {
    name: klinechartsEhhdg416GrMeta?.name ?? "indexcomponents-klinecharts___zh___default",
    path: klinechartsEhhdg416GrMeta?.path ?? "/indexcomponents/klinecharts",
    meta: klinechartsEhhdg416GrMeta || {},
    alias: klinechartsEhhdg416GrMeta?.alias || [],
    redirect: klinechartsEhhdg416GrMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/klinecharts.vue").then(m => m.default || m)
  },
  {
    name: klinechartsEhhdg416GrMeta?.name ?? "indexcomponents-klinecharts___zh",
    path: klinechartsEhhdg416GrMeta?.path ?? "/zh/indexcomponents/klinecharts",
    meta: klinechartsEhhdg416GrMeta || {},
    alias: klinechartsEhhdg416GrMeta?.alias || [],
    redirect: klinechartsEhhdg416GrMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/klinecharts.vue").then(m => m.default || m)
  },
  {
    name: new_45index_45chart3iHVMzc362Meta?.name ?? "indexcomponents-new-index-chart___en",
    path: new_45index_45chart3iHVMzc362Meta?.path ?? "/en/indexcomponents/new-index-chart",
    meta: new_45index_45chart3iHVMzc362Meta || {},
    alias: new_45index_45chart3iHVMzc362Meta?.alias || [],
    redirect: new_45index_45chart3iHVMzc362Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/new-index-chart.vue").then(m => m.default || m)
  },
  {
    name: new_45index_45chart3iHVMzc362Meta?.name ?? "indexcomponents-new-index-chart___zh___default",
    path: new_45index_45chart3iHVMzc362Meta?.path ?? "/indexcomponents/new-index-chart",
    meta: new_45index_45chart3iHVMzc362Meta || {},
    alias: new_45index_45chart3iHVMzc362Meta?.alias || [],
    redirect: new_45index_45chart3iHVMzc362Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/new-index-chart.vue").then(m => m.default || m)
  },
  {
    name: new_45index_45chart3iHVMzc362Meta?.name ?? "indexcomponents-new-index-chart___zh",
    path: new_45index_45chart3iHVMzc362Meta?.path ?? "/zh/indexcomponents/new-index-chart",
    meta: new_45index_45chart3iHVMzc362Meta || {},
    alias: new_45index_45chart3iHVMzc362Meta?.alias || [],
    redirect: new_45index_45chart3iHVMzc362Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/new-index-chart.vue").then(m => m.default || m)
  },
  {
    name: online3wCcs0ogFgMeta?.name ?? "indexcomponents-online___en",
    path: online3wCcs0ogFgMeta?.path ?? "/en/indexcomponents/online",
    meta: online3wCcs0ogFgMeta || {},
    alias: online3wCcs0ogFgMeta?.alias || [],
    redirect: online3wCcs0ogFgMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/online.vue").then(m => m.default || m)
  },
  {
    name: online3wCcs0ogFgMeta?.name ?? "indexcomponents-online___zh___default",
    path: online3wCcs0ogFgMeta?.path ?? "/indexcomponents/online",
    meta: online3wCcs0ogFgMeta || {},
    alias: online3wCcs0ogFgMeta?.alias || [],
    redirect: online3wCcs0ogFgMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/online.vue").then(m => m.default || m)
  },
  {
    name: online3wCcs0ogFgMeta?.name ?? "indexcomponents-online___zh",
    path: online3wCcs0ogFgMeta?.path ?? "/zh/indexcomponents/online",
    meta: online3wCcs0ogFgMeta || {},
    alias: online3wCcs0ogFgMeta?.alias || [],
    redirect: online3wCcs0ogFgMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/online.vue").then(m => m.default || m)
  },
  {
    name: platformLowestPriceHw3fHRaNJuMeta?.name ?? "indexcomponents-platformLowestPrice___en",
    path: platformLowestPriceHw3fHRaNJuMeta?.path ?? "/en/indexcomponents/platformLowestPrice",
    meta: platformLowestPriceHw3fHRaNJuMeta || {},
    alias: platformLowestPriceHw3fHRaNJuMeta?.alias || [],
    redirect: platformLowestPriceHw3fHRaNJuMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/platformLowestPrice.vue").then(m => m.default || m)
  },
  {
    name: platformLowestPriceHw3fHRaNJuMeta?.name ?? "indexcomponents-platformLowestPrice___zh___default",
    path: platformLowestPriceHw3fHRaNJuMeta?.path ?? "/indexcomponents/platformLowestPrice",
    meta: platformLowestPriceHw3fHRaNJuMeta || {},
    alias: platformLowestPriceHw3fHRaNJuMeta?.alias || [],
    redirect: platformLowestPriceHw3fHRaNJuMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/platformLowestPrice.vue").then(m => m.default || m)
  },
  {
    name: platformLowestPriceHw3fHRaNJuMeta?.name ?? "indexcomponents-platformLowestPrice___zh",
    path: platformLowestPriceHw3fHRaNJuMeta?.path ?? "/zh/indexcomponents/platformLowestPrice",
    meta: platformLowestPriceHw3fHRaNJuMeta || {},
    alias: platformLowestPriceHw3fHRaNJuMeta?.alias || [],
    redirect: platformLowestPriceHw3fHRaNJuMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/platformLowestPrice.vue").then(m => m.default || m)
  },
  {
    name: scaleNumASTcg0OJeGMeta?.name ?? "indexcomponents-scaleNum___en",
    path: scaleNumASTcg0OJeGMeta?.path ?? "/en/indexcomponents/scaleNum",
    meta: scaleNumASTcg0OJeGMeta || {},
    alias: scaleNumASTcg0OJeGMeta?.alias || [],
    redirect: scaleNumASTcg0OJeGMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/scaleNum.vue").then(m => m.default || m)
  },
  {
    name: scaleNumASTcg0OJeGMeta?.name ?? "indexcomponents-scaleNum___zh___default",
    path: scaleNumASTcg0OJeGMeta?.path ?? "/indexcomponents/scaleNum",
    meta: scaleNumASTcg0OJeGMeta || {},
    alias: scaleNumASTcg0OJeGMeta?.alias || [],
    redirect: scaleNumASTcg0OJeGMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/scaleNum.vue").then(m => m.default || m)
  },
  {
    name: scaleNumASTcg0OJeGMeta?.name ?? "indexcomponents-scaleNum___zh",
    path: scaleNumASTcg0OJeGMeta?.path ?? "/zh/indexcomponents/scaleNum",
    meta: scaleNumASTcg0OJeGMeta || {},
    alias: scaleNumASTcg0OJeGMeta?.alias || [],
    redirect: scaleNumASTcg0OJeGMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/scaleNum.vue").then(m => m.default || m)
  },
  {
    name: transicationUfX7FWUzXdMeta?.name ?? "indexcomponents-transication___en",
    path: transicationUfX7FWUzXdMeta?.path ?? "/en/indexcomponents/transication",
    meta: transicationUfX7FWUzXdMeta || {},
    alias: transicationUfX7FWUzXdMeta?.alias || [],
    redirect: transicationUfX7FWUzXdMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/transication.vue").then(m => m.default || m)
  },
  {
    name: transicationUfX7FWUzXdMeta?.name ?? "indexcomponents-transication___zh___default",
    path: transicationUfX7FWUzXdMeta?.path ?? "/indexcomponents/transication",
    meta: transicationUfX7FWUzXdMeta || {},
    alias: transicationUfX7FWUzXdMeta?.alias || [],
    redirect: transicationUfX7FWUzXdMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/transication.vue").then(m => m.default || m)
  },
  {
    name: transicationUfX7FWUzXdMeta?.name ?? "indexcomponents-transication___zh",
    path: transicationUfX7FWUzXdMeta?.path ?? "/zh/indexcomponents/transication",
    meta: transicationUfX7FWUzXdMeta || {},
    alias: transicationUfX7FWUzXdMeta?.alias || [],
    redirect: transicationUfX7FWUzXdMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/transication.vue").then(m => m.default || m)
  },
  {
    name: update_45historyApuSb19KlCMeta?.name ?? "indexcomponents-update-history___en",
    path: update_45historyApuSb19KlCMeta?.path ?? "/en/indexcomponents/update-history",
    meta: update_45historyApuSb19KlCMeta || {},
    alias: update_45historyApuSb19KlCMeta?.alias || [],
    redirect: update_45historyApuSb19KlCMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/update-history.vue").then(m => m.default || m)
  },
  {
    name: update_45historyApuSb19KlCMeta?.name ?? "indexcomponents-update-history___zh___default",
    path: update_45historyApuSb19KlCMeta?.path ?? "/indexcomponents/update-history",
    meta: update_45historyApuSb19KlCMeta || {},
    alias: update_45historyApuSb19KlCMeta?.alias || [],
    redirect: update_45historyApuSb19KlCMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/update-history.vue").then(m => m.default || m)
  },
  {
    name: update_45historyApuSb19KlCMeta?.name ?? "indexcomponents-update-history___zh",
    path: update_45historyApuSb19KlCMeta?.path ?? "/zh/indexcomponents/update-history",
    meta: update_45historyApuSb19KlCMeta || {},
    alias: update_45historyApuSb19KlCMeta?.alias || [],
    redirect: update_45historyApuSb19KlCMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/update-history.vue").then(m => m.default || m)
  },
  {
    name: _91steamId_93ejBKlYzldKMeta?.name ?? "inventory-steamId___en",
    path: _91steamId_93ejBKlYzldKMeta?.path ?? "/en/inventory/:steamId()",
    meta: _91steamId_93ejBKlYzldKMeta || {},
    alias: _91steamId_93ejBKlYzldKMeta?.alias || [],
    redirect: _91steamId_93ejBKlYzldKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/[steamId].vue").then(m => m.default || m)
  },
  {
    name: _91steamId_93ejBKlYzldKMeta?.name ?? "inventory-steamId___zh___default",
    path: _91steamId_93ejBKlYzldKMeta?.path ?? "/inventory/:steamId()",
    meta: _91steamId_93ejBKlYzldKMeta || {},
    alias: _91steamId_93ejBKlYzldKMeta?.alias || [],
    redirect: _91steamId_93ejBKlYzldKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/[steamId].vue").then(m => m.default || m)
  },
  {
    name: _91steamId_93ejBKlYzldKMeta?.name ?? "inventory-steamId___zh",
    path: _91steamId_93ejBKlYzldKMeta?.path ?? "/zh/inventory/:steamId()",
    meta: _91steamId_93ejBKlYzldKMeta || {},
    alias: _91steamId_93ejBKlYzldKMeta?.alias || [],
    redirect: _91steamId_93ejBKlYzldKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/[steamId].vue").then(m => m.default || m)
  },
  {
    name: InventoryInfoO7TVLrnUSNMeta?.name ?? "inventory-components-InventoryInfo___en",
    path: InventoryInfoO7TVLrnUSNMeta?.path ?? "/en/inventory/components/InventoryInfo",
    meta: InventoryInfoO7TVLrnUSNMeta || {},
    alias: InventoryInfoO7TVLrnUSNMeta?.alias || [],
    redirect: InventoryInfoO7TVLrnUSNMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryInfo.vue").then(m => m.default || m)
  },
  {
    name: InventoryInfoO7TVLrnUSNMeta?.name ?? "inventory-components-InventoryInfo___zh___default",
    path: InventoryInfoO7TVLrnUSNMeta?.path ?? "/inventory/components/InventoryInfo",
    meta: InventoryInfoO7TVLrnUSNMeta || {},
    alias: InventoryInfoO7TVLrnUSNMeta?.alias || [],
    redirect: InventoryInfoO7TVLrnUSNMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryInfo.vue").then(m => m.default || m)
  },
  {
    name: InventoryInfoO7TVLrnUSNMeta?.name ?? "inventory-components-InventoryInfo___zh",
    path: InventoryInfoO7TVLrnUSNMeta?.path ?? "/zh/inventory/components/InventoryInfo",
    meta: InventoryInfoO7TVLrnUSNMeta || {},
    alias: InventoryInfoO7TVLrnUSNMeta?.alias || [],
    redirect: InventoryInfoO7TVLrnUSNMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryInfo.vue").then(m => m.default || m)
  },
  {
    name: inventoryitemcydpf5jVZhMeta?.name ?? "inventory-components-inventoryitem___en",
    path: inventoryitemcydpf5jVZhMeta?.path ?? "/en/inventory/components/inventoryitem",
    meta: inventoryitemcydpf5jVZhMeta || {},
    alias: inventoryitemcydpf5jVZhMeta?.alias || [],
    redirect: inventoryitemcydpf5jVZhMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/inventoryitem.vue").then(m => m.default || m)
  },
  {
    name: inventoryitemcydpf5jVZhMeta?.name ?? "inventory-components-inventoryitem___zh___default",
    path: inventoryitemcydpf5jVZhMeta?.path ?? "/inventory/components/inventoryitem",
    meta: inventoryitemcydpf5jVZhMeta || {},
    alias: inventoryitemcydpf5jVZhMeta?.alias || [],
    redirect: inventoryitemcydpf5jVZhMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/inventoryitem.vue").then(m => m.default || m)
  },
  {
    name: inventoryitemcydpf5jVZhMeta?.name ?? "inventory-components-inventoryitem___zh",
    path: inventoryitemcydpf5jVZhMeta?.path ?? "/zh/inventory/components/inventoryitem",
    meta: inventoryitemcydpf5jVZhMeta || {},
    alias: inventoryitemcydpf5jVZhMeta?.alias || [],
    redirect: inventoryitemcydpf5jVZhMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/inventoryitem.vue").then(m => m.default || m)
  },
  {
    name: InventorySearchAcAJ6bsFFHMeta?.name ?? "inventory-components-InventorySearch___en",
    path: InventorySearchAcAJ6bsFFHMeta?.path ?? "/en/inventory/components/InventorySearch",
    meta: InventorySearchAcAJ6bsFFHMeta || {},
    alias: InventorySearchAcAJ6bsFFHMeta?.alias || [],
    redirect: InventorySearchAcAJ6bsFFHMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventorySearch.vue").then(m => m.default || m)
  },
  {
    name: InventorySearchAcAJ6bsFFHMeta?.name ?? "inventory-components-InventorySearch___zh___default",
    path: InventorySearchAcAJ6bsFFHMeta?.path ?? "/inventory/components/InventorySearch",
    meta: InventorySearchAcAJ6bsFFHMeta || {},
    alias: InventorySearchAcAJ6bsFFHMeta?.alias || [],
    redirect: InventorySearchAcAJ6bsFFHMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventorySearch.vue").then(m => m.default || m)
  },
  {
    name: InventorySearchAcAJ6bsFFHMeta?.name ?? "inventory-components-InventorySearch___zh",
    path: InventorySearchAcAJ6bsFFHMeta?.path ?? "/zh/inventory/components/InventorySearch",
    meta: InventorySearchAcAJ6bsFFHMeta || {},
    alias: InventorySearchAcAJ6bsFFHMeta?.alias || [],
    redirect: InventorySearchAcAJ6bsFFHMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventorySearch.vue").then(m => m.default || m)
  },
  {
    name: InventoryToplVSRXXtSxKMeta?.name ?? "inventory-components-InventoryTop___en",
    path: InventoryToplVSRXXtSxKMeta?.path ?? "/en/inventory/components/InventoryTop",
    meta: InventoryToplVSRXXtSxKMeta || {},
    alias: InventoryToplVSRXXtSxKMeta?.alias || [],
    redirect: InventoryToplVSRXXtSxKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryTop.vue").then(m => m.default || m)
  },
  {
    name: InventoryToplVSRXXtSxKMeta?.name ?? "inventory-components-InventoryTop___zh___default",
    path: InventoryToplVSRXXtSxKMeta?.path ?? "/inventory/components/InventoryTop",
    meta: InventoryToplVSRXXtSxKMeta || {},
    alias: InventoryToplVSRXXtSxKMeta?.alias || [],
    redirect: InventoryToplVSRXXtSxKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryTop.vue").then(m => m.default || m)
  },
  {
    name: InventoryToplVSRXXtSxKMeta?.name ?? "inventory-components-InventoryTop___zh",
    path: InventoryToplVSRXXtSxKMeta?.path ?? "/zh/inventory/components/InventoryTop",
    meta: InventoryToplVSRXXtSxKMeta || {},
    alias: InventoryToplVSRXXtSxKMeta?.alias || [],
    redirect: InventoryToplVSRXXtSxKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryTop.vue").then(m => m.default || m)
  },
  {
    name: SevenDaysInventoryqFExv7VYFqMeta?.name ?? "inventory-components-SevenDaysInventory___en",
    path: SevenDaysInventoryqFExv7VYFqMeta?.path ?? "/en/inventory/components/SevenDaysInventory",
    meta: SevenDaysInventoryqFExv7VYFqMeta || {},
    alias: SevenDaysInventoryqFExv7VYFqMeta?.alias || [],
    redirect: SevenDaysInventoryqFExv7VYFqMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventory.vue").then(m => m.default || m)
  },
  {
    name: SevenDaysInventoryqFExv7VYFqMeta?.name ?? "inventory-components-SevenDaysInventory___zh___default",
    path: SevenDaysInventoryqFExv7VYFqMeta?.path ?? "/inventory/components/SevenDaysInventory",
    meta: SevenDaysInventoryqFExv7VYFqMeta || {},
    alias: SevenDaysInventoryqFExv7VYFqMeta?.alias || [],
    redirect: SevenDaysInventoryqFExv7VYFqMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventory.vue").then(m => m.default || m)
  },
  {
    name: SevenDaysInventoryqFExv7VYFqMeta?.name ?? "inventory-components-SevenDaysInventory___zh",
    path: SevenDaysInventoryqFExv7VYFqMeta?.path ?? "/zh/inventory/components/SevenDaysInventory",
    meta: SevenDaysInventoryqFExv7VYFqMeta || {},
    alias: SevenDaysInventoryqFExv7VYFqMeta?.alias || [],
    redirect: SevenDaysInventoryqFExv7VYFqMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventory.vue").then(m => m.default || m)
  },
  {
    name: SevenDaysInventoryDetailnSgiBE6rgBMeta?.name ?? "inventory-components-SevenDaysInventoryDetail___en",
    path: SevenDaysInventoryDetailnSgiBE6rgBMeta?.path ?? "/en/inventory/components/SevenDaysInventoryDetail",
    meta: SevenDaysInventoryDetailnSgiBE6rgBMeta || {},
    alias: SevenDaysInventoryDetailnSgiBE6rgBMeta?.alias || [],
    redirect: SevenDaysInventoryDetailnSgiBE6rgBMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventoryDetail.vue").then(m => m.default || m)
  },
  {
    name: SevenDaysInventoryDetailnSgiBE6rgBMeta?.name ?? "inventory-components-SevenDaysInventoryDetail___zh___default",
    path: SevenDaysInventoryDetailnSgiBE6rgBMeta?.path ?? "/inventory/components/SevenDaysInventoryDetail",
    meta: SevenDaysInventoryDetailnSgiBE6rgBMeta || {},
    alias: SevenDaysInventoryDetailnSgiBE6rgBMeta?.alias || [],
    redirect: SevenDaysInventoryDetailnSgiBE6rgBMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventoryDetail.vue").then(m => m.default || m)
  },
  {
    name: SevenDaysInventoryDetailnSgiBE6rgBMeta?.name ?? "inventory-components-SevenDaysInventoryDetail___zh",
    path: SevenDaysInventoryDetailnSgiBE6rgBMeta?.path ?? "/zh/inventory/components/SevenDaysInventoryDetail",
    meta: SevenDaysInventoryDetailnSgiBE6rgBMeta || {},
    alias: SevenDaysInventoryDetailnSgiBE6rgBMeta?.alias || [],
    redirect: SevenDaysInventoryDetailnSgiBE6rgBMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventoryDetail.vue").then(m => m.default || m)
  },
  {
    name: indexKDk185p2imMeta?.name ?? "inventory___en",
    path: indexKDk185p2imMeta?.path ?? "/en/inventory",
    meta: indexKDk185p2imMeta || {},
    alias: indexKDk185p2imMeta?.alias || [],
    redirect: indexKDk185p2imMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDk185p2imMeta?.name ?? "inventory___zh___default",
    path: indexKDk185p2imMeta?.path ?? "/inventory",
    meta: indexKDk185p2imMeta || {},
    alias: indexKDk185p2imMeta?.alias || [],
    redirect: indexKDk185p2imMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDk185p2imMeta?.name ?? "inventory___zh",
    path: indexKDk185p2imMeta?.path ?? "/zh/inventory",
    meta: indexKDk185p2imMeta || {},
    alias: indexKDk185p2imMeta?.alias || [],
    redirect: indexKDk185p2imMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: myGRehYgZpzFMeta?.name ?? "inventory-my___en",
    path: myGRehYgZpzFMeta?.path ?? "/en/inventory/my",
    meta: myGRehYgZpzFMeta || {},
    alias: myGRehYgZpzFMeta?.alias || [],
    redirect: myGRehYgZpzFMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/my.vue").then(m => m.default || m)
  },
  {
    name: myGRehYgZpzFMeta?.name ?? "inventory-my___zh___default",
    path: myGRehYgZpzFMeta?.path ?? "/inventory/my",
    meta: myGRehYgZpzFMeta || {},
    alias: myGRehYgZpzFMeta?.alias || [],
    redirect: myGRehYgZpzFMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/my.vue").then(m => m.default || m)
  },
  {
    name: myGRehYgZpzFMeta?.name ?? "inventory-my___zh",
    path: myGRehYgZpzFMeta?.path ?? "/zh/inventory/my",
    meta: myGRehYgZpzFMeta || {},
    alias: myGRehYgZpzFMeta?.alias || [],
    redirect: myGRehYgZpzFMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/my.vue").then(m => m.default || m)
  },
  {
    name: filter_45drawerPjQAyhIu0hMeta?.name ?? "ladders-components-filter-drawer___en",
    path: filter_45drawerPjQAyhIu0hMeta?.path ?? "/en/ladders/components/filter-drawer",
    meta: filter_45drawerPjQAyhIu0hMeta || {},
    alias: filter_45drawerPjQAyhIu0hMeta?.alias || [],
    redirect: filter_45drawerPjQAyhIu0hMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/filter-drawer.vue").then(m => m.default || m)
  },
  {
    name: filter_45drawerPjQAyhIu0hMeta?.name ?? "ladders-components-filter-drawer___zh___default",
    path: filter_45drawerPjQAyhIu0hMeta?.path ?? "/ladders/components/filter-drawer",
    meta: filter_45drawerPjQAyhIu0hMeta || {},
    alias: filter_45drawerPjQAyhIu0hMeta?.alias || [],
    redirect: filter_45drawerPjQAyhIu0hMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/filter-drawer.vue").then(m => m.default || m)
  },
  {
    name: filter_45drawerPjQAyhIu0hMeta?.name ?? "ladders-components-filter-drawer___zh",
    path: filter_45drawerPjQAyhIu0hMeta?.path ?? "/zh/ladders/components/filter-drawer",
    meta: filter_45drawerPjQAyhIu0hMeta || {},
    alias: filter_45drawerPjQAyhIu0hMeta?.alias || [],
    redirect: filter_45drawerPjQAyhIu0hMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/filter-drawer.vue").then(m => m.default || m)
  },
  {
    name: ladder_45center_45content2a6Rw8xgVIMeta?.name ?? "ladders-components-ladder-center-content___en",
    path: ladder_45center_45content2a6Rw8xgVIMeta?.path ?? "/en/ladders/components/ladder-center-content",
    meta: ladder_45center_45content2a6Rw8xgVIMeta || {},
    alias: ladder_45center_45content2a6Rw8xgVIMeta?.alias || [],
    redirect: ladder_45center_45content2a6Rw8xgVIMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladder-center-content.vue").then(m => m.default || m)
  },
  {
    name: ladder_45center_45content2a6Rw8xgVIMeta?.name ?? "ladders-components-ladder-center-content___zh___default",
    path: ladder_45center_45content2a6Rw8xgVIMeta?.path ?? "/ladders/components/ladder-center-content",
    meta: ladder_45center_45content2a6Rw8xgVIMeta || {},
    alias: ladder_45center_45content2a6Rw8xgVIMeta?.alias || [],
    redirect: ladder_45center_45content2a6Rw8xgVIMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladder-center-content.vue").then(m => m.default || m)
  },
  {
    name: ladder_45center_45content2a6Rw8xgVIMeta?.name ?? "ladders-components-ladder-center-content___zh",
    path: ladder_45center_45content2a6Rw8xgVIMeta?.path ?? "/zh/ladders/components/ladder-center-content",
    meta: ladder_45center_45content2a6Rw8xgVIMeta || {},
    alias: ladder_45center_45content2a6Rw8xgVIMeta?.alias || [],
    redirect: ladder_45center_45content2a6Rw8xgVIMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladder-center-content.vue").then(m => m.default || m)
  },
  {
    name: ladders_45lowestPricexVLmwCS7iAMeta?.name ?? "ladders-components-ladders-lowestPrice___en",
    path: ladders_45lowestPricexVLmwCS7iAMeta?.path ?? "/en/ladders/components/ladders-lowestPrice",
    meta: ladders_45lowestPricexVLmwCS7iAMeta || {},
    alias: ladders_45lowestPricexVLmwCS7iAMeta?.alias || [],
    redirect: ladders_45lowestPricexVLmwCS7iAMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladders-lowestPrice.vue").then(m => m.default || m)
  },
  {
    name: ladders_45lowestPricexVLmwCS7iAMeta?.name ?? "ladders-components-ladders-lowestPrice___zh___default",
    path: ladders_45lowestPricexVLmwCS7iAMeta?.path ?? "/ladders/components/ladders-lowestPrice",
    meta: ladders_45lowestPricexVLmwCS7iAMeta || {},
    alias: ladders_45lowestPricexVLmwCS7iAMeta?.alias || [],
    redirect: ladders_45lowestPricexVLmwCS7iAMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladders-lowestPrice.vue").then(m => m.default || m)
  },
  {
    name: ladders_45lowestPricexVLmwCS7iAMeta?.name ?? "ladders-components-ladders-lowestPrice___zh",
    path: ladders_45lowestPricexVLmwCS7iAMeta?.path ?? "/zh/ladders/components/ladders-lowestPrice",
    meta: ladders_45lowestPricexVLmwCS7iAMeta || {},
    alias: ladders_45lowestPricexVLmwCS7iAMeta?.alias || [],
    redirect: ladders_45lowestPricexVLmwCS7iAMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladders-lowestPrice.vue").then(m => m.default || m)
  },
  {
    name: price_45dropdownfwyA023jw3Meta?.name ?? "ladders-components-price-dropdown___en",
    path: price_45dropdownfwyA023jw3Meta?.path ?? "/en/ladders/components/price-dropdown",
    meta: price_45dropdownfwyA023jw3Meta || {},
    alias: price_45dropdownfwyA023jw3Meta?.alias || [],
    redirect: price_45dropdownfwyA023jw3Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/price-dropdown.vue").then(m => m.default || m)
  },
  {
    name: price_45dropdownfwyA023jw3Meta?.name ?? "ladders-components-price-dropdown___zh___default",
    path: price_45dropdownfwyA023jw3Meta?.path ?? "/ladders/components/price-dropdown",
    meta: price_45dropdownfwyA023jw3Meta || {},
    alias: price_45dropdownfwyA023jw3Meta?.alias || [],
    redirect: price_45dropdownfwyA023jw3Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/price-dropdown.vue").then(m => m.default || m)
  },
  {
    name: price_45dropdownfwyA023jw3Meta?.name ?? "ladders-components-price-dropdown___zh",
    path: price_45dropdownfwyA023jw3Meta?.path ?? "/zh/ladders/components/price-dropdown",
    meta: price_45dropdownfwyA023jw3Meta || {},
    alias: price_45dropdownfwyA023jw3Meta?.alias || [],
    redirect: price_45dropdownfwyA023jw3Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/price-dropdown.vue").then(m => m.default || m)
  },
  {
    name: sellNums_45dropdownPy8GwDJFDaMeta?.name ?? "ladders-components-sellNums-dropdown___en",
    path: sellNums_45dropdownPy8GwDJFDaMeta?.path ?? "/en/ladders/components/sellNums-dropdown",
    meta: sellNums_45dropdownPy8GwDJFDaMeta || {},
    alias: sellNums_45dropdownPy8GwDJFDaMeta?.alias || [],
    redirect: sellNums_45dropdownPy8GwDJFDaMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/sellNums-dropdown.vue").then(m => m.default || m)
  },
  {
    name: sellNums_45dropdownPy8GwDJFDaMeta?.name ?? "ladders-components-sellNums-dropdown___zh___default",
    path: sellNums_45dropdownPy8GwDJFDaMeta?.path ?? "/ladders/components/sellNums-dropdown",
    meta: sellNums_45dropdownPy8GwDJFDaMeta || {},
    alias: sellNums_45dropdownPy8GwDJFDaMeta?.alias || [],
    redirect: sellNums_45dropdownPy8GwDJFDaMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/sellNums-dropdown.vue").then(m => m.default || m)
  },
  {
    name: sellNums_45dropdownPy8GwDJFDaMeta?.name ?? "ladders-components-sellNums-dropdown___zh",
    path: sellNums_45dropdownPy8GwDJFDaMeta?.path ?? "/zh/ladders/components/sellNums-dropdown",
    meta: sellNums_45dropdownPy8GwDJFDaMeta || {},
    alias: sellNums_45dropdownPy8GwDJFDaMeta?.alias || [],
    redirect: sellNums_45dropdownPy8GwDJFDaMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/sellNums-dropdown.vue").then(m => m.default || m)
  },
  {
    name: swap_45pageSHorOX9WYzMeta?.name ?? "ladders-components-swap-page___en",
    path: swap_45pageSHorOX9WYzMeta?.path ?? "/en/ladders/components/swap-page",
    meta: swap_45pageSHorOX9WYzMeta || {},
    alias: swap_45pageSHorOX9WYzMeta?.alias || [],
    redirect: swap_45pageSHorOX9WYzMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-page.vue").then(m => m.default || m)
  },
  {
    name: swap_45pageSHorOX9WYzMeta?.name ?? "ladders-components-swap-page___zh___default",
    path: swap_45pageSHorOX9WYzMeta?.path ?? "/ladders/components/swap-page",
    meta: swap_45pageSHorOX9WYzMeta || {},
    alias: swap_45pageSHorOX9WYzMeta?.alias || [],
    redirect: swap_45pageSHorOX9WYzMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-page.vue").then(m => m.default || m)
  },
  {
    name: swap_45pageSHorOX9WYzMeta?.name ?? "ladders-components-swap-page___zh",
    path: swap_45pageSHorOX9WYzMeta?.path ?? "/zh/ladders/components/swap-page",
    meta: swap_45pageSHorOX9WYzMeta || {},
    alias: swap_45pageSHorOX9WYzMeta?.alias || [],
    redirect: swap_45pageSHorOX9WYzMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-page.vue").then(m => m.default || m)
  },
  {
    name: swap_45platform_45comparesoYNfJVyL6Meta?.name ?? "ladders-components-swap-platform-compare___en",
    path: swap_45platform_45comparesoYNfJVyL6Meta?.path ?? "/en/ladders/components/swap-platform-compare",
    meta: swap_45platform_45comparesoYNfJVyL6Meta || {},
    alias: swap_45platform_45comparesoYNfJVyL6Meta?.alias || [],
    redirect: swap_45platform_45comparesoYNfJVyL6Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-platform-compare.vue").then(m => m.default || m)
  },
  {
    name: swap_45platform_45comparesoYNfJVyL6Meta?.name ?? "ladders-components-swap-platform-compare___zh___default",
    path: swap_45platform_45comparesoYNfJVyL6Meta?.path ?? "/ladders/components/swap-platform-compare",
    meta: swap_45platform_45comparesoYNfJVyL6Meta || {},
    alias: swap_45platform_45comparesoYNfJVyL6Meta?.alias || [],
    redirect: swap_45platform_45comparesoYNfJVyL6Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-platform-compare.vue").then(m => m.default || m)
  },
  {
    name: swap_45platform_45comparesoYNfJVyL6Meta?.name ?? "ladders-components-swap-platform-compare___zh",
    path: swap_45platform_45comparesoYNfJVyL6Meta?.path ?? "/zh/ladders/components/swap-platform-compare",
    meta: swap_45platform_45comparesoYNfJVyL6Meta || {},
    alias: swap_45platform_45comparesoYNfJVyL6Meta?.alias || [],
    redirect: swap_45platform_45comparesoYNfJVyL6Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-platform-compare.vue").then(m => m.default || m)
  },
  {
    name: transication_45dropdownx8pT38LJ6MMeta?.name ?? "ladders-components-transication-dropdown___en",
    path: transication_45dropdownx8pT38LJ6MMeta?.path ?? "/en/ladders/components/transication-dropdown",
    meta: transication_45dropdownx8pT38LJ6MMeta || {},
    alias: transication_45dropdownx8pT38LJ6MMeta?.alias || [],
    redirect: transication_45dropdownx8pT38LJ6MMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/transication-dropdown.vue").then(m => m.default || m)
  },
  {
    name: transication_45dropdownx8pT38LJ6MMeta?.name ?? "ladders-components-transication-dropdown___zh___default",
    path: transication_45dropdownx8pT38LJ6MMeta?.path ?? "/ladders/components/transication-dropdown",
    meta: transication_45dropdownx8pT38LJ6MMeta || {},
    alias: transication_45dropdownx8pT38LJ6MMeta?.alias || [],
    redirect: transication_45dropdownx8pT38LJ6MMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/transication-dropdown.vue").then(m => m.default || m)
  },
  {
    name: transication_45dropdownx8pT38LJ6MMeta?.name ?? "ladders-components-transication-dropdown___zh",
    path: transication_45dropdownx8pT38LJ6MMeta?.path ?? "/zh/ladders/components/transication-dropdown",
    meta: transication_45dropdownx8pT38LJ6MMeta || {},
    alias: transication_45dropdownx8pT38LJ6MMeta?.alias || [],
    redirect: transication_45dropdownx8pT38LJ6MMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/transication-dropdown.vue").then(m => m.default || m)
  },
  {
    name: indexwfLcFeARXUMeta?.name ?? "ladders___en",
    path: indexwfLcFeARXUMeta?.path ?? "/en/ladders",
    meta: indexwfLcFeARXUMeta || {},
    alias: indexwfLcFeARXUMeta?.alias || [],
    redirect: indexwfLcFeARXUMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/index.vue").then(m => m.default || m)
  },
  {
    name: indexwfLcFeARXUMeta?.name ?? "ladders___zh___default",
    path: indexwfLcFeARXUMeta?.path ?? "/ladders",
    meta: indexwfLcFeARXUMeta || {},
    alias: indexwfLcFeARXUMeta?.alias || [],
    redirect: indexwfLcFeARXUMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/index.vue").then(m => m.default || m)
  },
  {
    name: indexwfLcFeARXUMeta?.name ?? "ladders___zh",
    path: indexwfLcFeARXUMeta?.path ?? "/zh/ladders",
    meta: indexwfLcFeARXUMeta || {},
    alias: indexwfLcFeARXUMeta?.alias || [],
    redirect: indexwfLcFeARXUMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/index.vue").then(m => m.default || m)
  },
  {
    name: steamDKjznFPEztMeta?.name ?? "login-steam___en",
    path: steamDKjznFPEztMeta?.path ?? "/en/login/steam",
    meta: steamDKjznFPEztMeta || {},
    alias: steamDKjznFPEztMeta?.alias || [],
    redirect: steamDKjznFPEztMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/steam.vue").then(m => m.default || m)
  },
  {
    name: steamDKjznFPEztMeta?.name ?? "login-steam___zh___default",
    path: steamDKjznFPEztMeta?.path ?? "/login/steam",
    meta: steamDKjznFPEztMeta || {},
    alias: steamDKjznFPEztMeta?.alias || [],
    redirect: steamDKjznFPEztMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/steam.vue").then(m => m.default || m)
  },
  {
    name: steamDKjznFPEztMeta?.name ?? "login-steam___zh",
    path: steamDKjznFPEztMeta?.path ?? "/zh/login/steam",
    meta: steamDKjznFPEztMeta || {},
    alias: steamDKjznFPEztMeta?.alias || [],
    redirect: steamDKjznFPEztMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/steam.vue").then(m => m.default || m)
  },
  {
    name: favoriteBLqk7c41HxMeta?.name ?? "mkt-favorite___en",
    path: favoriteBLqk7c41HxMeta?.path ?? "/en/mkt/favorite",
    meta: favoriteBLqk7c41HxMeta || {},
    alias: favoriteBLqk7c41HxMeta?.alias || [],
    redirect: favoriteBLqk7c41HxMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/favorite.vue").then(m => m.default || m)
  },
  {
    name: favoriteBLqk7c41HxMeta?.name ?? "mkt-favorite___zh___default",
    path: favoriteBLqk7c41HxMeta?.path ?? "/mkt/favorite",
    meta: favoriteBLqk7c41HxMeta || {},
    alias: favoriteBLqk7c41HxMeta?.alias || [],
    redirect: favoriteBLqk7c41HxMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/favorite.vue").then(m => m.default || m)
  },
  {
    name: favoriteBLqk7c41HxMeta?.name ?? "mkt-favorite___zh",
    path: favoriteBLqk7c41HxMeta?.path ?? "/zh/mkt/favorite",
    meta: favoriteBLqk7c41HxMeta || {},
    alias: favoriteBLqk7c41HxMeta?.alias || [],
    redirect: favoriteBLqk7c41HxMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/favorite.vue").then(m => m.default || m)
  },
  {
    name: indexHgbfAsRuJKMeta?.name ?? "mkt___en",
    path: indexHgbfAsRuJKMeta?.path ?? "/en/mkt",
    meta: indexHgbfAsRuJKMeta || {},
    alias: indexHgbfAsRuJKMeta?.alias || [],
    redirect: indexHgbfAsRuJKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/index.vue").then(m => m.default || m)
  },
  {
    name: indexHgbfAsRuJKMeta?.name ?? "mkt___zh___default",
    path: indexHgbfAsRuJKMeta?.path ?? "/mkt",
    meta: indexHgbfAsRuJKMeta || {},
    alias: indexHgbfAsRuJKMeta?.alias || [],
    redirect: indexHgbfAsRuJKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/index.vue").then(m => m.default || m)
  },
  {
    name: indexHgbfAsRuJKMeta?.name ?? "mkt___zh",
    path: indexHgbfAsRuJKMeta?.path ?? "/zh/mkt",
    meta: indexHgbfAsRuJKMeta || {},
    alias: indexHgbfAsRuJKMeta?.alias || [],
    redirect: indexHgbfAsRuJKMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/index.vue").then(m => m.default || m)
  },
  {
    name: addFilterConditiondihcyLKygVMeta?.name ?? "selection-components-addFilterCondition___en",
    path: addFilterConditiondihcyLKygVMeta?.path ?? "/en/selection/components/addFilterCondition",
    meta: addFilterConditiondihcyLKygVMeta || {},
    alias: addFilterConditiondihcyLKygVMeta?.alias || [],
    redirect: addFilterConditiondihcyLKygVMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/addFilterCondition.vue").then(m => m.default || m)
  },
  {
    name: addFilterConditiondihcyLKygVMeta?.name ?? "selection-components-addFilterCondition___zh___default",
    path: addFilterConditiondihcyLKygVMeta?.path ?? "/selection/components/addFilterCondition",
    meta: addFilterConditiondihcyLKygVMeta || {},
    alias: addFilterConditiondihcyLKygVMeta?.alias || [],
    redirect: addFilterConditiondihcyLKygVMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/addFilterCondition.vue").then(m => m.default || m)
  },
  {
    name: addFilterConditiondihcyLKygVMeta?.name ?? "selection-components-addFilterCondition___zh",
    path: addFilterConditiondihcyLKygVMeta?.path ?? "/zh/selection/components/addFilterCondition",
    meta: addFilterConditiondihcyLKygVMeta || {},
    alias: addFilterConditiondihcyLKygVMeta?.alias || [],
    redirect: addFilterConditiondihcyLKygVMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/addFilterCondition.vue").then(m => m.default || m)
  },
  {
    name: mkt_45valueViewerevFH4YaIkaMeta?.name ?? "selection-components-mkt-valueViewer___en",
    path: mkt_45valueViewerevFH4YaIkaMeta?.path ?? "/en/selection/components/mkt-valueViewer",
    meta: mkt_45valueViewerevFH4YaIkaMeta || {},
    alias: mkt_45valueViewerevFH4YaIkaMeta?.alias || [],
    redirect: mkt_45valueViewerevFH4YaIkaMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/mkt-valueViewer.vue").then(m => m.default || m)
  },
  {
    name: mkt_45valueViewerevFH4YaIkaMeta?.name ?? "selection-components-mkt-valueViewer___zh___default",
    path: mkt_45valueViewerevFH4YaIkaMeta?.path ?? "/selection/components/mkt-valueViewer",
    meta: mkt_45valueViewerevFH4YaIkaMeta || {},
    alias: mkt_45valueViewerevFH4YaIkaMeta?.alias || [],
    redirect: mkt_45valueViewerevFH4YaIkaMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/mkt-valueViewer.vue").then(m => m.default || m)
  },
  {
    name: mkt_45valueViewerevFH4YaIkaMeta?.name ?? "selection-components-mkt-valueViewer___zh",
    path: mkt_45valueViewerevFH4YaIkaMeta?.path ?? "/zh/selection/components/mkt-valueViewer",
    meta: mkt_45valueViewerevFH4YaIkaMeta || {},
    alias: mkt_45valueViewerevFH4YaIkaMeta?.alias || [],
    redirect: mkt_45valueViewerevFH4YaIkaMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/mkt-valueViewer.vue").then(m => m.default || m)
  },
  {
    name: favoriterghmJJqlfLMeta?.name ?? "selection-favorite___en",
    path: favoriterghmJJqlfLMeta?.path ?? "/en/selection/favorite",
    meta: favoriterghmJJqlfLMeta || {},
    alias: favoriterghmJJqlfLMeta?.alias || [],
    redirect: favoriterghmJJqlfLMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/favorite.vue").then(m => m.default || m)
  },
  {
    name: favoriterghmJJqlfLMeta?.name ?? "selection-favorite___zh___default",
    path: favoriterghmJJqlfLMeta?.path ?? "/selection/favorite",
    meta: favoriterghmJJqlfLMeta || {},
    alias: favoriterghmJJqlfLMeta?.alias || [],
    redirect: favoriterghmJJqlfLMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/favorite.vue").then(m => m.default || m)
  },
  {
    name: favoriterghmJJqlfLMeta?.name ?? "selection-favorite___zh",
    path: favoriterghmJJqlfLMeta?.path ?? "/zh/selection/favorite",
    meta: favoriterghmJJqlfLMeta || {},
    alias: favoriterghmJJqlfLMeta?.alias || [],
    redirect: favoriterghmJJqlfLMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/favorite.vue").then(m => m.default || m)
  },
  {
    name: indexvUvQkGCFn0Meta?.name ?? "selection___en",
    path: indexvUvQkGCFn0Meta?.path ?? "/en/selection",
    meta: indexvUvQkGCFn0Meta || {},
    alias: indexvUvQkGCFn0Meta?.alias || [],
    redirect: indexvUvQkGCFn0Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/index.vue").then(m => m.default || m)
  },
  {
    name: indexvUvQkGCFn0Meta?.name ?? "selection___zh___default",
    path: indexvUvQkGCFn0Meta?.path ?? "/selection",
    meta: indexvUvQkGCFn0Meta || {},
    alias: indexvUvQkGCFn0Meta?.alias || [],
    redirect: indexvUvQkGCFn0Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/index.vue").then(m => m.default || m)
  },
  {
    name: indexvUvQkGCFn0Meta?.name ?? "selection___zh",
    path: indexvUvQkGCFn0Meta?.path ?? "/zh/selection",
    meta: indexvUvQkGCFn0Meta || {},
    alias: indexvUvQkGCFn0Meta?.alias || [],
    redirect: indexvUvQkGCFn0Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/index.vue").then(m => m.default || m)
  },
  {
    name: chooseSkinDialogmk5wPewZdjMeta?.name ?? "skindiy-components-chooseSkinDialog___en",
    path: chooseSkinDialogmk5wPewZdjMeta?.path ?? "/en/skindiy/components/chooseSkinDialog",
    meta: chooseSkinDialogmk5wPewZdjMeta || {},
    alias: chooseSkinDialogmk5wPewZdjMeta?.alias || [],
    redirect: chooseSkinDialogmk5wPewZdjMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseSkinDialog.vue").then(m => m.default || m)
  },
  {
    name: chooseSkinDialogmk5wPewZdjMeta?.name ?? "skindiy-components-chooseSkinDialog___zh___default",
    path: chooseSkinDialogmk5wPewZdjMeta?.path ?? "/skindiy/components/chooseSkinDialog",
    meta: chooseSkinDialogmk5wPewZdjMeta || {},
    alias: chooseSkinDialogmk5wPewZdjMeta?.alias || [],
    redirect: chooseSkinDialogmk5wPewZdjMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseSkinDialog.vue").then(m => m.default || m)
  },
  {
    name: chooseSkinDialogmk5wPewZdjMeta?.name ?? "skindiy-components-chooseSkinDialog___zh",
    path: chooseSkinDialogmk5wPewZdjMeta?.path ?? "/zh/skindiy/components/chooseSkinDialog",
    meta: chooseSkinDialogmk5wPewZdjMeta || {},
    alias: chooseSkinDialogmk5wPewZdjMeta?.alias || [],
    redirect: chooseSkinDialogmk5wPewZdjMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseSkinDialog.vue").then(m => m.default || m)
  },
  {
    name: chooseStickerDialog6R1roFN4afMeta?.name ?? "skindiy-components-chooseStickerDialog___en",
    path: chooseStickerDialog6R1roFN4afMeta?.path ?? "/en/skindiy/components/chooseStickerDialog",
    meta: chooseStickerDialog6R1roFN4afMeta || {},
    alias: chooseStickerDialog6R1roFN4afMeta?.alias || [],
    redirect: chooseStickerDialog6R1roFN4afMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseStickerDialog.vue").then(m => m.default || m)
  },
  {
    name: chooseStickerDialog6R1roFN4afMeta?.name ?? "skindiy-components-chooseStickerDialog___zh___default",
    path: chooseStickerDialog6R1roFN4afMeta?.path ?? "/skindiy/components/chooseStickerDialog",
    meta: chooseStickerDialog6R1roFN4afMeta || {},
    alias: chooseStickerDialog6R1roFN4afMeta?.alias || [],
    redirect: chooseStickerDialog6R1roFN4afMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseStickerDialog.vue").then(m => m.default || m)
  },
  {
    name: chooseStickerDialog6R1roFN4afMeta?.name ?? "skindiy-components-chooseStickerDialog___zh",
    path: chooseStickerDialog6R1roFN4afMeta?.path ?? "/zh/skindiy/components/chooseStickerDialog",
    meta: chooseStickerDialog6R1roFN4afMeta || {},
    alias: chooseStickerDialog6R1roFN4afMeta?.alias || [],
    redirect: chooseStickerDialog6R1roFN4afMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseStickerDialog.vue").then(m => m.default || m)
  },
  {
    name: itemzqI1eVG2KsMeta?.name ?? "skindiy-components-item___en",
    path: itemzqI1eVG2KsMeta?.path ?? "/en/skindiy/components/item",
    meta: itemzqI1eVG2KsMeta || {},
    alias: itemzqI1eVG2KsMeta?.alias || [],
    redirect: itemzqI1eVG2KsMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/item.vue").then(m => m.default || m)
  },
  {
    name: itemzqI1eVG2KsMeta?.name ?? "skindiy-components-item___zh___default",
    path: itemzqI1eVG2KsMeta?.path ?? "/skindiy/components/item",
    meta: itemzqI1eVG2KsMeta || {},
    alias: itemzqI1eVG2KsMeta?.alias || [],
    redirect: itemzqI1eVG2KsMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/item.vue").then(m => m.default || m)
  },
  {
    name: itemzqI1eVG2KsMeta?.name ?? "skindiy-components-item___zh",
    path: itemzqI1eVG2KsMeta?.path ?? "/zh/skindiy/components/item",
    meta: itemzqI1eVG2KsMeta || {},
    alias: itemzqI1eVG2KsMeta?.alias || [],
    redirect: itemzqI1eVG2KsMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/item.vue").then(m => m.default || m)
  },
  {
    name: indexYie7rmbEG7Meta?.name ?? "skindiy___en",
    path: indexYie7rmbEG7Meta?.path ?? "/en/skindiy",
    meta: indexYie7rmbEG7Meta || {},
    alias: indexYie7rmbEG7Meta?.alias || [],
    redirect: indexYie7rmbEG7Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/index.vue").then(m => m.default || m)
  },
  {
    name: indexYie7rmbEG7Meta?.name ?? "skindiy___zh___default",
    path: indexYie7rmbEG7Meta?.path ?? "/skindiy",
    meta: indexYie7rmbEG7Meta || {},
    alias: indexYie7rmbEG7Meta?.alias || [],
    redirect: indexYie7rmbEG7Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/index.vue").then(m => m.default || m)
  },
  {
    name: indexYie7rmbEG7Meta?.name ?? "skindiy___zh",
    path: indexYie7rmbEG7Meta?.path ?? "/zh/skindiy",
    meta: indexYie7rmbEG7Meta || {},
    alias: indexYie7rmbEG7Meta?.alias || [],
    redirect: indexYie7rmbEG7Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/index.vue").then(m => m.default || m)
  },
  {
    name: statusKfT2bRsdkUMeta?.name ?? "status___en",
    path: statusKfT2bRsdkUMeta?.path ?? "/en/status",
    meta: statusKfT2bRsdkUMeta || {},
    alias: statusKfT2bRsdkUMeta?.alias || [],
    redirect: statusKfT2bRsdkUMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/status.vue").then(m => m.default || m)
  },
  {
    name: statusKfT2bRsdkUMeta?.name ?? "status___zh___default",
    path: statusKfT2bRsdkUMeta?.path ?? "/status",
    meta: statusKfT2bRsdkUMeta || {},
    alias: statusKfT2bRsdkUMeta?.alias || [],
    redirect: statusKfT2bRsdkUMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/status.vue").then(m => m.default || m)
  },
  {
    name: statusKfT2bRsdkUMeta?.name ?? "status___zh",
    path: statusKfT2bRsdkUMeta?.path ?? "/zh/status",
    meta: statusKfT2bRsdkUMeta || {},
    alias: statusKfT2bRsdkUMeta?.alias || [],
    redirect: statusKfT2bRsdkUMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/status.vue").then(m => m.default || m)
  },
  {
    name: chartsRMb8V0pZ0iMeta?.name ?? "statuscomponents-charts___en",
    path: chartsRMb8V0pZ0iMeta?.path ?? "/en/statuscomponents/charts",
    meta: chartsRMb8V0pZ0iMeta || {},
    alias: chartsRMb8V0pZ0iMeta?.alias || [],
    redirect: chartsRMb8V0pZ0iMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/statuscomponents/charts.vue").then(m => m.default || m)
  },
  {
    name: chartsRMb8V0pZ0iMeta?.name ?? "statuscomponents-charts___zh___default",
    path: chartsRMb8V0pZ0iMeta?.path ?? "/statuscomponents/charts",
    meta: chartsRMb8V0pZ0iMeta || {},
    alias: chartsRMb8V0pZ0iMeta?.alias || [],
    redirect: chartsRMb8V0pZ0iMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/statuscomponents/charts.vue").then(m => m.default || m)
  },
  {
    name: chartsRMb8V0pZ0iMeta?.name ?? "statuscomponents-charts___zh",
    path: chartsRMb8V0pZ0iMeta?.path ?? "/zh/statuscomponents/charts",
    meta: chartsRMb8V0pZ0iMeta || {},
    alias: chartsRMb8V0pZ0iMeta?.alias || [],
    redirect: chartsRMb8V0pZ0iMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/statuscomponents/charts.vue").then(m => m.default || m)
  },
  {
    name: _91type_93b2rREbSfGXMeta?.name ?? "terms-type___en",
    path: _91type_93b2rREbSfGXMeta?.path ?? "/en/terms/:type()",
    meta: _91type_93b2rREbSfGXMeta || {},
    alias: _91type_93b2rREbSfGXMeta?.alias || [],
    redirect: _91type_93b2rREbSfGXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/terms/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93b2rREbSfGXMeta?.name ?? "terms-type___zh___default",
    path: _91type_93b2rREbSfGXMeta?.path ?? "/terms/:type()",
    meta: _91type_93b2rREbSfGXMeta || {},
    alias: _91type_93b2rREbSfGXMeta?.alias || [],
    redirect: _91type_93b2rREbSfGXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/terms/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93b2rREbSfGXMeta?.name ?? "terms-type___zh",
    path: _91type_93b2rREbSfGXMeta?.path ?? "/zh/terms/:type()",
    meta: _91type_93b2rREbSfGXMeta || {},
    alias: _91type_93b2rREbSfGXMeta?.alias || [],
    redirect: _91type_93b2rREbSfGXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/terms/[type].vue").then(m => m.default || m)
  },
  {
    name: indexbcN0HR41p8Meta?.name ?? "trend___en",
    path: indexbcN0HR41p8Meta?.path ?? "/en/trend",
    meta: indexbcN0HR41p8Meta || {},
    alias: indexbcN0HR41p8Meta?.alias || [],
    redirect: indexbcN0HR41p8Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/index.vue").then(m => m.default || m)
  },
  {
    name: indexbcN0HR41p8Meta?.name ?? "trend___zh___default",
    path: indexbcN0HR41p8Meta?.path ?? "/trend",
    meta: indexbcN0HR41p8Meta || {},
    alias: indexbcN0HR41p8Meta?.alias || [],
    redirect: indexbcN0HR41p8Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/index.vue").then(m => m.default || m)
  },
  {
    name: indexbcN0HR41p8Meta?.name ?? "trend___zh",
    path: indexbcN0HR41p8Meta?.path ?? "/zh/trend",
    meta: indexbcN0HR41p8Meta || {},
    alias: indexbcN0HR41p8Meta?.alias || [],
    redirect: indexbcN0HR41p8Meta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/index.vue").then(m => m.default || m)
  },
  {
    name: dataItema1d2sUeq4ZMeta?.name ?? "trend-indexconpoment-dataItem___en",
    path: dataItema1d2sUeq4ZMeta?.path ?? "/en/trend/indexconpoment/dataItem",
    meta: dataItema1d2sUeq4ZMeta || {},
    alias: dataItema1d2sUeq4ZMeta?.alias || [],
    redirect: dataItema1d2sUeq4ZMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/dataItem.vue").then(m => m.default || m)
  },
  {
    name: dataItema1d2sUeq4ZMeta?.name ?? "trend-indexconpoment-dataItem___zh___default",
    path: dataItema1d2sUeq4ZMeta?.path ?? "/trend/indexconpoment/dataItem",
    meta: dataItema1d2sUeq4ZMeta || {},
    alias: dataItema1d2sUeq4ZMeta?.alias || [],
    redirect: dataItema1d2sUeq4ZMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/dataItem.vue").then(m => m.default || m)
  },
  {
    name: dataItema1d2sUeq4ZMeta?.name ?? "trend-indexconpoment-dataItem___zh",
    path: dataItema1d2sUeq4ZMeta?.path ?? "/zh/trend/indexconpoment/dataItem",
    meta: dataItema1d2sUeq4ZMeta || {},
    alias: dataItema1d2sUeq4ZMeta?.alias || [],
    redirect: dataItema1d2sUeq4ZMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/dataItem.vue").then(m => m.default || m)
  },
  {
    name: trendTbEIJ82LnXMeta?.name ?? "trend-indexconpoment-trend___en",
    path: trendTbEIJ82LnXMeta?.path ?? "/en/trend/indexconpoment/trend",
    meta: trendTbEIJ82LnXMeta || {},
    alias: trendTbEIJ82LnXMeta?.alias || [],
    redirect: trendTbEIJ82LnXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/trend.vue").then(m => m.default || m)
  },
  {
    name: trendTbEIJ82LnXMeta?.name ?? "trend-indexconpoment-trend___zh___default",
    path: trendTbEIJ82LnXMeta?.path ?? "/trend/indexconpoment/trend",
    meta: trendTbEIJ82LnXMeta || {},
    alias: trendTbEIJ82LnXMeta?.alias || [],
    redirect: trendTbEIJ82LnXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/trend.vue").then(m => m.default || m)
  },
  {
    name: trendTbEIJ82LnXMeta?.name ?? "trend-indexconpoment-trend___zh",
    path: trendTbEIJ82LnXMeta?.path ?? "/zh/trend/indexconpoment/trend",
    meta: trendTbEIJ82LnXMeta || {},
    alias: trendTbEIJ82LnXMeta?.alias || [],
    redirect: trendTbEIJ82LnXMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/trend.vue").then(m => m.default || m)
  },
  {
    name: updatedqV7MR5cdcMeta?.name ?? "update___en",
    path: updatedqV7MR5cdcMeta?.path ?? "/en/update",
    meta: updatedqV7MR5cdcMeta || {},
    alias: updatedqV7MR5cdcMeta?.alias || [],
    redirect: updatedqV7MR5cdcMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/update.vue").then(m => m.default || m)
  },
  {
    name: updatedqV7MR5cdcMeta?.name ?? "update___zh___default",
    path: updatedqV7MR5cdcMeta?.path ?? "/update",
    meta: updatedqV7MR5cdcMeta || {},
    alias: updatedqV7MR5cdcMeta?.alias || [],
    redirect: updatedqV7MR5cdcMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/update.vue").then(m => m.default || m)
  },
  {
    name: updatedqV7MR5cdcMeta?.name ?? "update___zh",
    path: updatedqV7MR5cdcMeta?.path ?? "/zh/update",
    meta: updatedqV7MR5cdcMeta || {},
    alias: updatedqV7MR5cdcMeta?.alias || [],
    redirect: updatedqV7MR5cdcMeta?.redirect || undefined,
    component: () => import("/root/workspace/steamdt.com_3as3/pages/update.vue").then(m => m.default || m)
  }
]