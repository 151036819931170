// plugins/google-analytics.js
export default defineNuxtPlugin(nuxtApp => {
  if (process.client) {
    console.log('google-analytics')
    // 加载谷歌统计脚本
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-TEZ3M92XBC'
    document.head.appendChild(script)
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())

    gtag('config', 'G-TEZ3M92XBC')
    gtag('js', new Date())
    gtag('config', 'AW-866524685')
  }
})
