export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path === '/inventory/vlt') {
    if (to.query.snapshotId) {
      return
    }
    if (to.params.steamId && to.query.appId) {
      return
    }
    return navigateTo('/inventory')
  }
})
